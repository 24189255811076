<template>
  <!-- 信链路经查询 -->
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <!-- <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template> -->
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>

<script>
import { selectAllForEnte } from '@/api/tradeFinancing.js'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      formInline: {

      },
      formItemArr: [
        { type: 'input', label: '信链编号', value: 'echainApplyNo', width: '120px' },
        { type: 'input', label: '开立方/转让方', value: 'payCmpName', width: '160px' },
        { type: 'input', label: '接收方', value: 'recvCmpName', width: '80px' }
      ],
      itemData: [
        { label: '信链编号', prop: 'echainApplyNo', width: 150 },
        { label: '生效时间', prop: 'echainRecvDate', width: 250 },
        { label: '开立方/转让方', prop: 'payCmpName', width: 380 },
        { label: '接收方', prop: 'recvCmpName', width: 180 },
        { label: '接收/转让金额', prop: 'echainAmt', width: 200 }
      ],
      operationButton: [
        { bType: 'primary', label: '《信链开立协议》', handleEvent: this.openFile, child: [{ val: 'openAggreement' }] },
        { bType: 'primary', label: '《信链凭证》', handleEvent: this.openFile, child: [{ val: 'certificate' }] },
        { bType: 'primary', label: '《信链转让协议》', handleEvent: this.openFile, child: [{ val: 'transferAggreement' }] },
        { bType: 'primary', label: '《信链流转单》', handleEvent: this.openFile, child: [{ val: 'transferOrder' }] }
      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: []
    }
  },
  computed: {
    FileUrl() {
      return this.$store.getters.getDictionaryItem('FILE_PREFIX_URL')
    }
  },
  methods: {
    getDate(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      selectAllForEnte(this.formInline, res => {
        this.loading = false
        this.listData = res.data.pageData.map(item => {
          if (item.paymentLetterId) {
            item.openAggreement = true
          }
          if (item.voucherId) {
            item.certificate = true
          }
          if (item.echainAgreeFileId) {
            item.transferAggreement = true
          }
          if (item.echainFlowFileId) {
            item.transferOrder = true
          }
          return item
        })
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    openFile({ id }) {
      const url = this.FileUrl[0].dictName + id
      window.open(url)
    }
  }
}
</script>

<style>

</style>
